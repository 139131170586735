<template>
  <div class="operation">
		<div class="search-box">
			<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
				<el-form-item label="操作名称">
					<el-input v-model="formSearch.title" placeholder="请输入操作名称"></el-input>
				</el-form-item>
				<el-form-item label="操作IP">
					<el-input v-model="formSearch.ip" placeholder="请输入操作IP"></el-input>
				</el-form-item>
				<el-form-item label="操作模块">
					<el-input v-model="formSearch.operateModule" placeholder="请输入操作模块"></el-input>
				</el-form-item>
				<el-form-item label="操作用户">
					<el-select v-model="formSearch.userId" clearable>
						<el-option v-for="(item,index) in formSearch.userList" :key="index" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="操作状态">
					<el-select v-model="formSearch.type" clearable>
						<el-option label="成功" value="1"></el-option>
						<el-option label="失败" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="操作时间">
					<el-date-picker v-model="formSearch.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"></el-date-picker>
				</el-form-item>
				<el-form-item style="text-align: right;" class="fr">
					<el-button type="primary" @click="searchNameFn">检索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- 列表 -->
		<div class="table-box">
			<el-card>
				<el-table v-loading="loadingFlag" border :data="tableData" @selection-change="handleSelectionChange" :row-style="{height:'48px'}" empty-text="加载中..." style="width: 100%;">
					<el-table-column type="selection" width="30"></el-table-column>
					<el-table-column type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
					<el-table-column prop="title" label="操作名称" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="url" label="请求URL" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="operateModule" label="操作模块" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="ip" label="操作IP" width="200"></el-table-column>
					<el-table-column prop="type" label="状态" width="100">
						<template slot-scope="scope">
							<span v-if="scope.row.type == '1'">成功</span>
							<span v-if="scope.row.type == '2'">失败</span>
						</template>
					</el-table-column>
					<el-table-column prop="userName" label="操作用户" width="100"></el-table-column>
					<el-table-column prop="createTime" label="操作时间" width="200"></el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
				<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportONE" style="float: left;margin-top:10px;margin-right: 20px;">{{exportOneName}}</el-button>
				<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportALLFn" style="float: left;margin-top:10px;">{{exportAllName}}</el-button>
				<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
			</el-card>
		</div>
  </div>
</template>

<script>
import { exportExcel } from '../../provider/index.js'
export default {
  name: 'operation',
  data() {
		return {
			baseUrl:this.$pulick.baseUrl,//默认路径目录  加载静态文件img
			//异常时间
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			formSearch:{
				title: '',
				ip: '',
				operateModule: '',
				userId: '',
				type: '',
				time: null,
				userList: [],
			},
			loadingFlag: false,
			dataTableEmpty:false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 13, //每页  条数
			totalItems: 0, //列表 总数
			multipleSelection: [], //导出参数   数据前  复选框
			exportOneName:"导出所选",
			exportAllName:"导出全部",
		};
  },
  mounted() {}, 
  created() {
		this.getUserList();//加载用户
		this.getPageList(); //加载列表
  },
  methods: {
		//加载用户
		getUserList() {
			this.$provider.get('/wg-system/user/listByTenantId', {}).then(res=>{
				this.formSearch.userList = res.data;
			})
		},
		//点击查询  按钮
		searchNameFn() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		//加载列表
		getPageList() {
			this.loadingFlag = true;//loading 显示
			this.dataTableEmpty = false;
			this.$provider.get('/wg-system/log/page',{
				params:{
					title: this.formSearch.title,
					ip: this.formSearch.ip,
					operateModule: this.formSearch.operateModule,
					userId: this.formSearch.userId,
					type: this.formSearch.type,
					startTime: this.formSearch.time != null ? this.formSearch.time[0] : "",
					endTime: this.formSearch.time != null ? this.formSearch.time[1] : "",
					current: this.currentPage,
					size: this.pageSize,
				}
			}).then(res=>{
				this.loadingFlag = false;//loading 消失
				this.dataTableEmpty = true;
				this.tableData = res.data.records;
				this.totalItems = res.data.total;
			})
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//表格 每行  复选框选值    导出
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		//导出所选
		exportONE() {
			let chk_value = '';
			this.multipleSelection.forEach(function(f, i) {
				if (i == 0) {
					chk_value = f.id;
				} else {
					chk_value += "," + f.id;
				}
			})
			if (chk_value.length != 0) {
				let myObj = {
					method: 'get',
					url: this.$pulick.urlStr + '/wg-system/log/exportByIds',
					fileName: '操作日志',
					params: 'ids='+chk_value+'&logFlag=true'
				}
				this.exportOneName = "正在导出中...";
				exportExcel(myObj,this);// 导出Excel公用方法
			} else {
				this.$message({
					message: '请先勾选',
					type: 'warning'
				});
				return false;
			}
		},
		//导出全部
		exportALLFn() {
			let startTime = this.formSearch.time != null ? this.formSearch.time[0] : "";
			let endTime = this.formSearch.time != null ? this.formSearch.time[1] : "";
			let myObj = {
				method: 'get',
				url: this.$pulick.urlStr + '/wg-system/log/export',
				fileName: '操作日志',
				params:'logFlag=true&title='+this.formSearch.title+'&ip='+this.formSearch.ip+'&operateModule='+this.formSearch.operateModule+'&userId='+this.formSearch.userId+'&type='+this.formSearch.type+'&startTime='+startTime+'&endTime='+endTime,
			} 
			this.exportAllName = "正在导出中...";
			exportExcel(myObj,this);// 导出Excel公用方法
		},
  }
};
</script>

<style scoped="scoped"></style>
